.css-lmgu5w:hover {
  background: transparent !important;
}
.css-ppwsfr:hover,
.css-ppwsfr[data-hover] {
  background: transparent !important;
}

.text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
