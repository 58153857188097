@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;1,200&family=Roboto:wght@300&display=swap');
body {
  font-family: 'Poppins', sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #418db8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #196591;
}

#chat-container::-webkit-scrollbar {
  display: none !important;
}

/* loader */

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999 !important;
  background: radial-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 253, 253, 0.5)
  );
}

.loaderImage-container {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translateX(-50%);
}

.heading {
  font-family: 'Poppins', sans-serif !important;
}

/* styles.css */
.main-container {
  position: relative;
}

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 99;
  border: 2px solid #17496d;
  border-radius: 50%;
  padding: 12px;
  background: #17496d;
  /* Add more styles as needed */
}

.chat-window {
  position: fixed;
  bottom: 10px;
  right: 50px;
  width: 35vw;
  height: 85vh;
  background-color: #fafafa;
  border-radius: 20px;
  z-index: 9999;
  /* Add more styles as needed */
}

@media screen and (max-width: 900px) {
  .chat-window {
    position: fixed;
    bottom: 50px;
    right: 10px;
    width: 80vw;
    /* height: 80vh; */
    background-color: #fafafa;
    border-radius: 20px;
    z-index: 9;
    /* Add more styles as needed */
  }
  .message-container {
    height: 55vh;
    max-height: 55vh;
    overflow-y: auto;
    border-bottom: 1px solid #acacac;
  }
}
@media screen and (min-width: 900px) {
  .chat-window {
    position: fixed;
    bottom: 10px;
    right: 20px;
    width: 30vw;
    /* height: 80vh; */
    background-color: #fafafa;
    border-radius: 20px;
    z-index: 9;
    /* Add more styles as needed */
  }
  .message-container {
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    border-bottom: 1px solid #acacac;
  }
}

.message-container::-webkit-scrollbar {
  display: none !important;
}

.bot-message {
  margin-top: 3px;
  color: #282828;
  background: rgba(104, 104, 104, 0.2);
  padding: 10px;
  border-radius: 6px;
  font-size: 13px;
  height: fit-content;
}
.user-message {
  font-weight: 400;
  color: #ffffff;
  background: #17496d;
  padding: 10px;
  border-radius: 6px;
  font-size: 13px;
  height: fit-content;
}





.values-container {
  max-width: 600px;
  margin: auto;
}

.values-container h2 {
  text-align: center;
}

.values-container ol {
  list-style-type: none;
  padding: 0;
}

.values-container li {
  margin-bottom: 20px;
}

.values-container li strong {
  font-weight: bold;
}
